//差异分析火山图
export default {
    color: ['#ff0202', '#08cc08', '#cfcfcf'],
    legend: {
        show: true,
        z: 10,
        top: "48%",
        right: "0",
        data: ['up', 'down', 'noDiff'],
        orient: "vertical",
        itemWidth: 10,
        itemHeight: 10
    },
    title: {
        left: 'center',
        text: 'Volcano Plot',
        subtext: ''
    },
    tooltip: {
        position: 'top',
        axisPointer: {
            type: "cross"
        },
        formatter: function (p) {
            return `GeneSymbol：${p.data[2]}<br/>log2(fold change)：${p.data[0].toFixed(2)}<br/>${p.data[3]}：${p.data[1].toFixed(2)}`
        }
    },
    grid: {
        right: "120"
    },
    xAxis: {
        name: "log2(fold change)",
        nameLocation: "center",
        nameTextStyle: {
            color: "#161616",
            fontSize: 14
        },
        nameGap: 55,
    },
    yAxis: {
        name: "",
        nameLocation: "center",
        nameTextStyle: {
            color: "#161616",
            fontSize: 14
        },
        nameGap: 30
    },
    dataZoom: [
        {
            type: 'inside'
        },
        // {
        //     type: 'slider',
        //     showDataShadow: false,
        //     handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        //     handleSize: '100%',
        //     height: 15
        // },
        {
            type: 'inside',
            orient: 'vertical'
        },
        // {
        //     type: 'slider',
        //     orient: 'vertical',
        //     showDataShadow: false,
        //     handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        //     handleSize: '100%',
        //     width: 15,
        //     right: "13%"
        // }
    ],
    series: [
        {
            name: "up",
            type: 'scatter',
            encode: {tooltip: [0, 1]},
            symbolSize: 5,
            data: []
        },
        {
            name: "down",
            type: 'scatter',
            encode: {tooltip: [0, 1]},
            symbolSize: 5,
            data: []
        },
        {
            name: "noDiff",
            type: 'scatter',
            encode: {tooltip: [0, 1]},
            symbolSize: 5,
            data: []
        }
    ]
}
