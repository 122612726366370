import ajax from './http';

//登录
export function login(params) {
    return ajax.post('/login', params)
}
//样本查询
export function sample_list(params) {
    return ajax.post('/sample_list', params);
}
//基因查询
export function gene_list() {
    return ajax.get('/gene_list');
}
//查询bioproject
export function project_list(params) {
    return ajax.post('/project_list', params)
}

//wgcna通过bioproject查询文件
export function wgcna_get_files(params){
    return ajax.post("/get_files",params)
}

//差异分析结果获取json生成热图
export function create_json(params) {
    return ajax.post('/create_json', params)
}

export function get_we_files(params) {
    return ajax.post('/get_we_files', params)
}

/* ------------------------------- 二期 -------------------------------------- */
//差异分析结果获取json生成热图
export function create_json_v2(params) {
    return ajax.post('/create_json_v2', params)
}

// 通过miRNA获取文件
export function get_target_files(miRNA){
    return ajax.post('/get_target_files',{id:miRNA})
}

// 获取miRNA_arraymodule
export function get_rna_array_module(acc){
    return ajax.post('/get_bged2_rnaarray_wgcna_list',{id:acc})
}