<template>
    <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
            <a-input v-model="longValue" @change="handleChange" @pressEnter="check" type="number" />
            <a-icon type="check" class="editable-cell-icon-check" @click="check" />
        </div>
        <div v-else class="editable-cell-text-wrapper">
            {{ value || ' ' }}
            <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
    </div>
</template>

<script>
export default {
    name: "EditableCellNumber",
    props:{
        number:Number,
        e:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            value: this.e ? this.number.toExponential(3) : this.number.toFixed(3),
            longValue:this.number,
            editable: false,
        };
    },
    methods: {
        handleChange(e) {
            const value = parseFloat(e.target.value);
            this.value = this.e ? value.toExponential(3) : value.toFixed(3) ;
        },
        check() {
            this.editable = false;
            this.$emit('change', this.longValue);
        },
        edit() {
            this.editable = true;
        },
    }
}
</script>

<style scoped>
.editable-cell-icon{
    float: right;
    margin-right: 0.5rem;
}
</style>